
import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Jobs - '
  },
  name: 'Jobs',
  components: {},
  data: () => ({}),
  computed: {}
});
